import {
  CartAction,
  CartOptimisticUpdateAction,
  CartChangesAcceptedAction,
  CART_LOAD,
  CART_LOAD_SUCCESS,
  CART_LOAD_FAILURE,
  CART_OPTIMISTIC_UPDATE,
  CART_CHANGES_ACCEPTED,
  CART_CLEAR_RECOMMENDATIONS,
  formatLineItemId,
} from './action-creators';
import { PAGE_LOAD_SUCCESS, UPDATE_APP_SHELL_DATA, UpdateAppShellDataAction } from '@polarnopyret/scope';
import currentPageIsCheckout from '../Checkout/Pages/Checkout/current-page-is-checkout';
import { COMPLETE_PURCHASE_WITHOUT_PAYMENT_GATEWAY_SUCCESS } from '../Checkout/action-creators';
import AppShellDataType from 'AppShell/AppShellContextData.type';
import CartUpdateViewModel from './Models/CartUpdateViewModel.type';
import { Action, CartType, PageType } from 'Shared/State';

type ImboxCartItem = { id: string, name: string, price: string, count: number, total: string, picture: string }

export default function (
  state: CartType = null,
  action: Action,
  currentPage: PageType,
  appShellData: AppShellDataType,
): CartType {
  if (state === null) {
    if (currentPageIsCheckout(currentPage)) {
      // @ts-ignore
      window._imbox && window._imbox.push(
        ['setCartItems', appShellData.cart.items?.map<ImboxCartItem>(item => (
          { id: item.extId, name: item.displayName, price: item.placedPrice.toString(), count: item.quantity, total: item.totalPlacedPrice.toString(), picture: document.location.protocol + "//" + document.location.host + item.imageUrl }
        )
        )]
      )
      return Object.assign(
        {
          isLoading: false,
          pendingChanges: [],
        },
        currentPage.cart,
      );
    } else {
      state = Object.assign({}, appShellData.cart, {
        isLoading: false,
        pendingChanges: [],
      });
    }
  }
  switch (action.type) {
    case CART_LOAD: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }
    case CART_LOAD_SUCCESS: {
      const newState = Object.assign({}, (action as CartAction).cart, {
        isLoading: state.pendingChanges.length > 0,
        pendingChanges: state.pendingChanges,
      });
      // @ts-ignore
      window._imbox && window._imbox.push(
        ['setCartItems', newState?.items?.map<ImboxCartItem>(item => (
          { id: item.extId, name: item.displayName, price: item.placedPrice.toString(), count: item.quantity, total: item.totalPlacedPrice.toString(), picture: document.location.protocol + "//" + document.location.host + item.imageUrl }
        )
        )]
      );
      return applyPendingChanges(newState, newState.pendingChanges);
    }
    case UPDATE_APP_SHELL_DATA: {
      return Object.assign({}, (action as UpdateAppShellDataAction<AppShellDataType>).appShellData.cart, {
        isLoading: false,
        pendingChanges: [],
      });
    }
    case CART_LOAD_FAILURE: {
      const firstPendingChange = state.pendingChanges[0];

      if (!firstPendingChange) {
        return state;
      }

      return Object.assign({}, state, {
        isLoading: false,
        pendingChanges: [],
        items: state.items.map(item => {
          let updatedItem: any;

          Object.keys(firstPendingChange.items).forEach(code => {
            if (code === item.code) {
              updatedItem = Object.assign({}, item, {
                quantity: firstPendingChange.items[code].previousQuantity,
              });
            }
          });

          return updatedItem || item;
        }),
      });
    }
    case CART_OPTIMISTIC_UPDATE: {
      const diff = [(action as CartOptimisticUpdateAction).diff];
      const newState = Object.assign({}, state);
      newState.pendingChanges = (newState.pendingChanges || []).concat(diff);
      return applyPendingChanges(newState, diff);
    }
    case CART_CHANGES_ACCEPTED: {
      const acceptedChangeIds = (action as CartChangesAcceptedAction).changes.map(c => c.id);
      const newState = Object.assign({}, state);
      newState.pendingChanges = newState.pendingChanges.filter(p => acceptedChangeIds.indexOf(p.id) === -1);
      newState.items = newState.items.filter(i => i.quantity > 0);
      return newState;
    }
    case COMPLETE_PURCHASE_WITHOUT_PAYMENT_GATEWAY_SUCCESS: {
      const newCart: CartType = {
        id: '',
        market: '',
        items: [],
        subTotal: 0,
        totalPrice: 0,
        subTotalWithOrderLevelDiscount: 0,
        orderLevelDiscount: 0,
        isLoading: false,
        pendingChanges: [],
        totalSavings: 0,
        trackingResult: {
          added: [],
          removed: [],
          current: [],
        },
        paymentFailed: false,
        errorMessage: [],
        validationItems: [],
        shippingTotal: 0,
        totalPlacedPrice: 0,
        totalListPrice: 0,
        cartSumInformation: []
      };
      return newCart;
    }
    case PAGE_LOAD_SUCCESS: {
      if (currentPageIsCheckout(currentPage)) {
        return Object.assign(
          {
            isLoading: false,
            pendingChanges: [],
          },
          currentPage.cart,
        );
      } else {
        return { ...state };
      }
    }
    case CART_CLEAR_RECOMMENDATIONS:
      return { ...state };
    default:
      return state;
  }
}

function applyPendingChanges(state: CartType, pendingChanges: CartUpdateViewModel[]) {
  if (!pendingChanges || !pendingChanges.length) {
    return state;
  }
  pendingChanges.forEach(pendingChange => {
    Object.keys(pendingChange.items).forEach(id => {
      const items = state.items.slice();
      const itemIndex = items.findIndex(i => formatLineItemId(i) === id);
      if (itemIndex !== -1) {
        items[itemIndex] = Object.assign({}, items[itemIndex], {
          quantity: pendingChange.items[id].newQuantity,
        });
      } else {
        // If we don't already have the item we can't do anything because we only
        // have the code and the quantity
        console.debug('Ignoring update to cart item not present in state, line id: ', id);
      }
      state.items = items;
    });
  });
  // We set the quantity to 0 in the diff to signify that it has been removed
  // but we never want 0 quantity in the state
  state.items = state.items.filter(i => i.quantity > 0);
  return state;
}
