import {
  get,
  post,
  pushState,
  URLX,
  pathCombine,
  addUserLog,
  onCrossWindow,
  emitCrossWindow,
  pageUpdateSuccess,
  pageUpdateFailure,
  pageUpdate,
  LINK_IDENTIFIER_HEADER,
  REQUEST_ACTION_HEADER,
  ADD_TO_CART_ACTION,
  LoadFailureAction,
} from '@polarnopyret/scope';
import deepmerge from 'deepmerge';
import { checkoutPageUrl } from 'Shared/known-urls';
import {
  CART_LOAD,
  CART_LOAD_SUCCESS,
  CART_LOAD_FAILURE,
  CART_OPTIMISTIC_UPDATE,
  CART_CHANGES_ACCEPTED,
  isValidQuantity,
  rejectInvalidQuantity,
  CartAction,
  CartOptimisticUpdateAction,
  CartChangesAcceptedAction,
  formatLineItemId,
} from '../Cart/action-creators';
import currentPageIsCheckout from './Pages/Checkout/current-page-is-checkout';
import CheckoutPageViewModelType from './Pages/Checkout/CheckoutPageViewModel.type';
import ShippingMethodViewModelType from 'Checkout/Shipping/ShippingMethodViewModel.type';
import ShippingAddressType from 'Checkout/Shipping/ShippingAddress.type';
import State, { Store, Dispatch, Action, CartDiffType } from 'Shared/State';
import { CheckoutPageStateType, CheckoutPageDiffType } from './Pages/Checkout/reducer';
import { batchActions } from 'redux-batched-actions';
import { CartEventLocation } from '../TrackingInformation/data-layer-actions';
import {
  addToCart as gtmAddToCart,
  removeFromCart as gtmRemoveFromCart,
  saveEmailToLocalStorage,
} from '../TrackingInformation';
import { KlarnaCheckoutShippingOptionChangeEventArgs } from './Pages/Checkout/CheckoutPage';
import CheckoutUpdateResult from './Pages/Checkout/CheckoutUpdateResult.type';

export const COMPLETE_PURCHASE = 'COMPLETE_PURCHASE';
export const COMPLETE_PURCHASE_WITHOUT_PAYMENT_GATEWAY_SUCCESS = 'COMPLETE_PURCHASE_WITHOUT_PAYMENT_GATEWAY_SUCCESS';
export const COMPLETE_PURCHASE_FAILURE = 'COMPLETE_PURCHASE_FAILURE';

export const CHECKOUT_OPTIMISTIC_UPDATE = 'CHECKOUT_OPTIMISTIC_UPDATE';
export const CHECKOUT_CHANGES_ACCEPTED = 'CHECKOUT_CHANGES_ACCEPTED';

let completePurchaseInProgress = false;

export type CheckoutOptimisticUpdateAction = Action & {
  diff: CheckoutPageDiffType;
};

export type CheckoutChangesAcceptedAction = Action & {
  changes: CheckoutPageDiffType[];
};

export function setShippingAddress(address: ShippingAddressType) {
  return updateShippingAddressAndSetCheckoutState({
    shippingAddress: address
  } as CheckoutPageDiffType);
}

export function onKlarnaShippingOptionChange(args: KlarnaCheckoutShippingOptionChangeEventArgs) {
  addUserLog('Setting shipping method to ' + args.id);
  return updateShippingOptionAndSetCheckoutState({
    shippingMethod: {
      selectedOptionId: args.id,
      name: args.name,
      taxAmount: args.tax_amount,
      taxRate: args.tax_rate,
      price: args.price
    }
  } as CheckoutPageDiffType);
}

export function setShippingMethod(method: ShippingMethodViewModelType) {
  addUserLog('Setting shipping method to ' + method.selectedOptionId);
  return updateShippingOptionAndSetCheckoutState({
    shippingMethod: method
  } as CheckoutPageDiffType);
  //return updateCheckoutStateAndSend({ shippingMethod: method } as CheckoutPageDiffType);
}

// export function setOrderer(orderer: OrdererType) {
//   addUserLog('Updating orderer form');
//   return updateCheckoutState({ orderer } as CheckoutPageDiffType);
// }

export function updateCartItemQuantity(
  lineId: number,
  code: string,
  quantity: number,
  location: CartEventLocation,
  ticket: string = null,
): any {
  if (!isValidQuantity(quantity)) {
    return rejectInvalidQuantity(code);
  }
  let id = 'line' + lineId;
  return updateCheckoutStateAndSend(
    {
      cart: {
        items: { [id]: { lineItemId: lineId, newQuantity: quantity, code } },
      },
    } as CheckoutPageDiffType,
    location,
    ticket,
  );
}

export function addCartItem(
  code: string,
  quantity: number,
  ticket: string,
  location: CartEventLocation,
  currentCategory: string,
  colorProductCode: string
) {
  if (!isValidQuantity(quantity)) {
    return rejectInvalidQuantity(code);
  }
  let id = 'line-0';
  return updateCheckoutStateAndSend(
    {
      cart: {
        items: { [id]: { lineItemId: 0, newQuantity: quantity, code, currentCategory, colorProductCode } },
      },
    } as CheckoutPageDiffType,
    location,
    ticket,
  );
}

export function removeCartItem(lineId: number, code: string, previousQuantity: number, location: CartEventLocation) {
  let id = 'line' + lineId;
  return updateCheckoutStateAndSend(
    { cart: { items: { [id]: { lineItemId: lineId, previousQuantity: previousQuantity, newQuantity: 0, code } } } } as CheckoutPageDiffType,
    location,
  );
}

export enum VoucherType {
  BonusCheck,
  SecondHandCheck
}

export function addVoucher(id: string, type: VoucherType) {
  const payload = type == VoucherType.BonusCheck ? { addedBonusChecks: [{ id }] } : { addedSecondHandChecks: [{ id }] }
  return updateCheckoutStateAndSend(payload as CheckoutPageDiffType);
}

export function removeVoucher(id: string, type: VoucherType) {
  const payload = type == VoucherType.BonusCheck ? { removedBonusChecks: [id] } : { removedSecondHandChecks: [id] }
  return updateCheckoutStateAndSend(payload as CheckoutPageDiffType);
}

// export function addBonusCheckCode(id: string) {
//   return updateCheckoutStateAndSend({ addedBonusChecks: [{ id }] } as CheckoutPageDiffType);
// }

// export function removeBonusCheckCode(id: string) {
//   return updateCheckoutStateAndSend({ removedBonusChecks: [id] } as CheckoutPageDiffType);
// }

// export function addSecondHandCheckCode(id: string) {
//   return updateCheckoutStateAndSend({ addedSecondHandChecks: [{ id }] } as CheckoutPageDiffType);
// }

// export function removeSecondHandCheckCode(id: string) {
//   return updateCheckoutStateAndSend({ removedSecondHandChecks: [id] } as CheckoutPageDiffType);
// }

export function addGiftCardCode(cardNumber: string) {
  return updateCheckoutStateAndSend({
    giftCards: [{ cardNumber }],
  } as CheckoutPageDiffType);
}

export function removeGiftCardCode(cardNumber: string) {
  return updateCheckoutStateAndSend({ removedGiftCards: [cardNumber] } as CheckoutPageDiffType);
}

export function addDiscountCode(code: string) {
  return updateCheckoutStateAndSend({ addedDiscountCodes: [code] } as CheckoutPageDiffType);
}

export function removeDiscountCode(code: string) {
  return updateCheckoutStateAndSend({ removedDiscountCodes: [code] } as CheckoutPageDiffType);
}

export function setApplyMembership(value: boolean) {
  return updateCheckoutStateAndSend({ applyMembership: value } as CheckoutPageDiffType);
}

function dispatchCartAndCheckoutUpdate(
  requestIds: string[],
  data: CheckoutPageViewModelType,
  getState: () => State,
  emitModified = true,
) {
  if (emitModified) {
    emitCrossWindow('cart-modified');
  }

  const cartLoadSuccess = () =>
  ({
    type: CART_LOAD_SUCCESS,
    cart: data.cart,
  } as CartAction);

  const checkoutUpdate = () => dispatchCheckoutUpdate(requestIds, data, getState, emitModified);

  return [cartLoadSuccess(), checkoutUpdate()];
}

export function sendPendingCheckoutRequests() {
  return (dispatch: Dispatch, getState: () => State) => {
    return flushCheckoutRequests(dispatch, getState, null);
  };
}

function dispatchCheckoutUpdate(
  requestIds: string[],
  data: CheckoutPageViewModelType,
  getState: () => State,
  emitModified = true,
) {
  if (emitModified) {
    emitCrossWindow('checkout-modified');
  }

  // We only update the page if the user didn't navigate away from the page
  // during the request
  if (currentPageIsCheckout(getState().currentPage)) {
    return pageUpdateSuccess(requestIds, data);
  }
}

let currentCheckoutUpdate = Promise.resolve(null);

onCrossWindow<Store>(['checkout-modified', 'cart-modified'], store => {
  if (!currentPageIsCheckout(store.getState().currentPage)) {
    return;
  }

  const requestId = Math.random().toString();
  store.dispatch(batchActions([{ type: CART_LOAD } as Action, pageUpdate(requestId)]));

  console.debug('Reloading checkout since it was modified in another window/tab');
  // Note that we don't update the cart here. That is handled in the action creators for the cart.
  const url = new URLX(checkoutPageUrl());
  currentCheckoutUpdate = currentCheckoutUpdate.then(() => {
    get(url)
      .then(r => r.json())
      .then((json: CheckoutPageViewModelType) => {
        store.dispatch(dispatchCheckoutUpdate([requestId], json, store.getState, false));
      })
      .catch(e => {
        console.error(e);
        store.dispatch({ type: CART_LOAD_FAILURE, error: e, url } as LoadFailureAction);
        store.dispatch(pageUpdateFailure(requestId));
      });
  });
});

let pendingTickets: string[] = [];

function handleCheckoutDiff(
  dispatch: Dispatch,
  getState: () => State,
  diff: CheckoutPageDiffType,
  location: CartEventLocation = null,
  ticket: string = null,
) {
  if (ticket) {
    pendingTickets.push(ticket);
  }
  diff.id = Math.random().toString();

  if (diff.cart && diff.cart.items) {
    Object.keys(diff.cart.items).forEach(id => {
      const state = getState();
      const existingItem = state.cart.items.find(i => formatLineItemId(i) === id);
      if (existingItem) {
        diff.cart.items[id].previousQuantity = existingItem.quantity;
      }
    });
  }

  if (completePurchaseInProgress) {
    return Promise.reject(null);
  }

  const actions: Action[] = [pageUpdate(diff.id)];

  if (diff.cart) {
    const cartDiff: CartDiffType = Object.assign(diff.cart, { id: Math.random().toString() });
    diff.cart = cartDiff;
    actions.push({ type: CART_LOAD } as Action);
    actions.push({
      type: CART_OPTIMISTIC_UPDATE,
      diff: cartDiff,
    } as CartOptimisticUpdateAction);
  }

  actions.push({
    type: CHECKOUT_OPTIMISTIC_UPDATE,
    diff,
  } as CheckoutOptimisticUpdateAction);

  dispatch(batchActions(actions));
}

function updateCheckoutStateAndSend(
  diff: CheckoutPageDiffType,
  location: CartEventLocation = null,
  ticket: string = null,
) {
  return (dispatch: Dispatch, getState: () => State) => {
    handleCheckoutDiff(dispatch, getState, diff, location, ticket);
    return flushCheckoutRequests(dispatch, getState, location);
  };
}

function updateShippingAddressAndSetCheckoutState(
  diff: CheckoutPageDiffType,
  location: CartEventLocation = null,
  ticket: string = null,
) {
  return (dispatch: Dispatch, getState: () => State) => {
    handleCheckoutDiff(dispatch, getState, diff, location, ticket);
    return updateShippingOption(dispatch, getState, 'updateaddress');
  };
}

function updateShippingOptionAndSetCheckoutState(
  diff: CheckoutPageDiffType,
  location: CartEventLocation = null,
  ticket: string = null,
) {
  return (dispatch: Dispatch, getState: () => State) => {
    handleCheckoutDiff(dispatch, getState, diff, location, ticket);
    return updateShippingOption(dispatch, getState, 'updateshipping');
  };
}

function updateCheckoutState(diff: CheckoutPageDiffType, location: CartEventLocation = null, ticket: string = null) {
  return (dispatch: Dispatch, getState: () => State) => {
    return handleCheckoutDiff(dispatch, getState, diff, location, ticket);
  };
}

function updateShippingOption(dispatch: Dispatch, getState: () => State, endpoint: string) {
  //const url = new URLX(pathCombine(checkoutPageUrl(), 'updateshipping'));
  const url = new URLX(pathCombine(checkoutPageUrl(), endpoint));
  console.log(url);
  const sendNextRequest = (failedAttempts = 0): Promise<any> => {
    if (failedAttempts === null || isNaN(failedAttempts)) {
      failedAttempts = 0;
    }

    const state = getState().currentPage as CheckoutPageStateType;
    const currentPendingChanges = state.pendingChanges || [];
    if (!currentPendingChanges.length) {
      return Promise.resolve(state.updateResult);
    }

    let totalDiff = {};
    const diffIds: string[] = [];
    currentPendingChanges.forEach(r => {
      totalDiff = deepmerge(totalDiff, r);
      diffIds.push(r.id);
    });

    let headers: { [name: string]: string };

    return post(url, totalDiff, headers)
      .then(r => r.json())
      .then((json: CheckoutPageViewModelType) => {
        if (json) {
          dispatch(
            batchActions([
              {
                type: CHECKOUT_CHANGES_ACCEPTED,
                changes: currentPendingChanges,
              } as CheckoutChangesAcceptedAction,
              ...dispatchCartAndCheckoutUpdate(diffIds, json, getState),
            ]),
          );

          return json.updateResult;
        }

        return { success: false, message: '' } as CheckoutUpdateResult;
      })
      .catch(e => {
        console.error(e);

        if (failedAttempts === 3) {
          return Promise.reject(e);
        }

        return sendNextRequest(failedAttempts + 1);
      });
  };

  currentCheckoutUpdate = currentCheckoutUpdate.then(sendNextRequest, sendNextRequest);
  return currentCheckoutUpdate;
}

function flushCheckoutRequests(dispatch: Dispatch, getState: () => State, location: CartEventLocation = null) {
  const url = new URLX(checkoutPageUrl());

  const sendNextRequest = (failedAttempts = 0): Promise<any> => {
    if (failedAttempts === null || isNaN(failedAttempts)) {
      failedAttempts = 0;
    }

    const state = getState().currentPage as CheckoutPageStateType;
    const currentPendingChanges = state.pendingChanges || [];
    if (!currentPendingChanges.length) {
      return Promise.resolve(state.updateResult);
    }

    let totalDiff = {};
    const diffIds: string[] = [];
    currentPendingChanges.forEach(r => {
      totalDiff = deepmerge(totalDiff, r);
      diffIds.push(r.id);
    });

    let headers: { [name: string]: string };
    if (pendingTickets.length) {
      const tickets = pendingTickets.join(',');
      pendingTickets = [];

      headers = {
        [LINK_IDENTIFIER_HEADER]: tickets,
        [REQUEST_ACTION_HEADER]: ADD_TO_CART_ACTION,
      };
    }

    return post(url, totalDiff, headers)
      .then(r => r.json())
      .then((json: CheckoutPageViewModelType) => {
        if (json) {
          if (json.cart?.trackingResult) {
            if (json.cart?.trackingResult.added.length > 0) {
              gtmAddToCart(json.cart?.trackingResult.added, location);
            }
            if (json.cart?.trackingResult.removed.length > 0) {
              gtmRemoveFromCart(json.cart?.trackingResult.removed, location);
            }
          }

          if (json.customer.emailHashed) {
            saveEmailToLocalStorage(json.customer.email, json.customer.emailHashed);
          }

          dispatch(
            batchActions([
              {
                type: CART_CHANGES_ACCEPTED,
                changes: currentPendingChanges.filter(c => c.cart != null).map(c => c.cart),
              } as CartChangesAcceptedAction,
              {
                type: CHECKOUT_CHANGES_ACCEPTED,
                changes: currentPendingChanges,
              } as CheckoutChangesAcceptedAction,
              ...dispatchCartAndCheckoutUpdate(diffIds, json, getState),
            ]),
          );

          return json.updateResult;
        }

        return {
          message: '',
          success: true
        };

      })
      .catch(e => {
        console.error(e);

        dispatch(
          batchActions([{ type: CART_LOAD_FAILURE, error: e, url } as LoadFailureAction, pageUpdateFailure(diffIds)]),
        );

        if (failedAttempts === 3) {
          return Promise.reject(e);
        }

        return sendNextRequest(failedAttempts + 1);
      });
  };

  currentCheckoutUpdate = currentCheckoutUpdate.then(sendNextRequest, sendNextRequest);
  return currentCheckoutUpdate;
}

// export function completePurchase(modelCreator: () => CompletePurchaseModelType) {
//   return (dispatch: Dispatch, getState: () => State) => {
//     if (completePurchaseInProgress) {
//       addUserLog('Clicked complete purchase while purchase completion was in progress');
//       return Promise.reject({ errorMessage: 'Complete purchase already in progress' });
//     }

//     addUserLog('Clicked complete purchase');

//     dispatch({
//       type: COMPLETE_PURCHASE,
//     });

//     completePurchaseInProgress = true;
//     const saveCustomer = (model: CompletePurchaseModelType) => {
//       savedCustomerInfo.save(model.form.orderer);
//       savedCustomerInfo.savePaymentMethodId(model.form.selectedPaymentMethodId);
//     };

//     return currentCheckoutUpdate.then(() => {
//       const model = modelCreator();
//       return post(pathCombine(checkoutPageUrl(), 'completePurchase'), model)
//         .then(r => r.json())
//         .then((json: CompletePurchaseResponseType) => {
//           if (json.newPageData) {
//             if (json.newPageData.paymentErrorMessage) {
//               addUserLog('Got payment error message: ' + json.newPageData.paymentErrorMessage);
//             }
//             const completeCheckoutFailure = () =>
//             ({
//               type: COMPLETE_PURCHASE_FAILURE,
//             } as Action);

//             const cartAndCheckout = dispatchCartAndCheckoutUpdate([], json.newPageData, getState);
//             const batchedArray = [completeCheckoutFailure, ...cartAndCheckout];

//             dispatch(batchActions(batchedArray as any));

//             completePurchaseInProgress = false;
//             return Promise.reject(null);
//           } else if (json.paymentRedirectUrl) {
//             // We don't set `completePurchaseInProgress` to false here because
//             // we don't know how long time it takes for the payment gateway
//             // to load and we don't want to allow you to complete the purchase
//             // again during that time.
//             saveCustomer(model);
//             if (json.paymentPostData) {
//               postForm(json.paymentRedirectUrl, json.paymentPostData, json.paymentFormEncoding);
//             } else {
//               window.location.href = json.paymentRedirectUrl;
//               return new Promise(() => null) as Promise<any>;
//             }
//           } else if (json.orderConfirmationRedirectUrl) {
//             saveCustomer(model);
//             return pushState(json.orderConfirmationRedirectUrl).then(() => {
//               dispatch({
//                 type: COMPLETE_PURCHASE_WITHOUT_PAYMENT_GATEWAY_SUCCESS,
//               } as Action);
//               completePurchaseInProgress = false;
//             });
//           } else {
//             console.error('completePurchase returned unknown result', json);
//             dispatch({
//               type: COMPLETE_PURCHASE_FAILURE,
//             } as Action);
//             completePurchaseInProgress = false;
//             return Promise.reject(null);
//           }
//         })
//         .catch(e => {
//           dispatch({
//             type: COMPLETE_PURCHASE_FAILURE,
//           } as Action);

//           completePurchaseInProgress = false;

//           return Promise.reject({ errorMessage: e.message });
//         });
//     });
//   };
// }
